import { useEffect, useState } from 'react'

export const useIsPrinting = () => {
    const [isPrinting, setIsPrinting] = useState(false)

    useEffect(() => {
        const beforeCallback = function () {
            setIsPrinting(true)
        }
        const afterCallback = function () {
            setIsPrinting(false)
        }
        window.addEventListener('beforeprint', beforeCallback)
        window.addEventListener('afterprint', afterCallback)

        return () => {
            window.removeEventListener('beforeprint', beforeCallback)
            window.removeEventListener('afterprint', afterCallback)
        }
    }, [])

    return isPrinting
}

import React from 'react'
import { useField } from 'formik'
import { Dropdown, DropdownProps } from '../ui'
import ErrorMessage from './ErrorMessage'

export default function DropdownField({
    name,
    trailingAddOn,
    showErrorMessage = true,
    anchor,
    ...props
}: {
    name: string
    trailingAddOn?: string
    showErrorMessage?: boolean
    anchor?: 'bottom' | 'top'
} & DropdownProps) {
    const [field, meta] = useField(name)

    return (
        <>
            <div className="relative">
                <Dropdown
                    hasError={showErrorMessage && !!meta.error}
                    anchor={anchor}
                    {...props}
                />
            </div>
            {showErrorMessage && <ErrorMessage name={field.name} />}
        </>
    )
}

import React, { useEffect, useMemo } from 'react'
import { useField } from 'formik'
import { DropdownItem } from 'types'
import { ResponseList } from 'api/types'
import {
    DropdownComboboxButton,
    DropdownComboboxInput,
    DropdownCombobox,
} from 'components/ui/Dropdowns/DropdownBase'
import DropdownComboboxOptions from 'components/ui/Dropdowns/DropdownBase/DropdownComboboxOptions'
import {
    useDropdown,
    useDropdownIsOpen,
    useDropdownSearch,
    useInfiniteDropdown,
} from 'components/ui/Dropdowns/hooks'
import InfiniteDropdownOptions from 'components/ui/Dropdowns/InfiniteDropdownOptions'
import { ComboboxDropdownItem } from 'components/ui'

interface InfiniteDropdownWithSearchFieldProps<T> {
    name: string
    disabled?: boolean
    queryFn: (filters?: {}) => Promise<ResponseList<T[]>>
    mapFn: (v: T) => DropdownItem
    queryFilters?: {}
}

function InfiniteDropdownWithSearchField<T>(
    props: InfiniteDropdownWithSearchFieldProps<T>
) {
    const { name, disabled, queryFilters, queryFn, mapFn } = props

    const { open, setIsOpen, comboboxRef } = useDropdownIsOpen()
    const [field, fieldMeta, helpers] = useField<DropdownItem | undefined>(name)
    const { onChange } = useDropdown((v) => {
        helpers.setValue(v || undefined)
        if (!!v) {
            setIsOpen(false)
        }
    })
    const { searchPhrase, setSearchPhrase, onClose } = useDropdownSearch()

    const { queryResult, results, observerRef } = useInfiniteDropdown({
        name,
        open,
        searchPhrase,
        queryFn,
        queryFilters: {
            ...queryFilters,
            search_global: searchPhrase || undefined,
        },
    })

    useEffect(() => {
        if (!open) {
            onClose()
        }
    }, [open, onClose])

    const items = useMemo(() => results?.map(mapFn) || [], [results, mapFn])

    return (
        <DropdownCombobox
            value={field.value}
            disabled={disabled}
            onChange={onChange}
            ref={comboboxRef}
        >
            <>
                <DropdownComboboxInput
                    open={open}
                    onChange={setSearchPhrase}
                    value={searchPhrase}
                    displayValue={field.value?.name || ''}
                    onBlur={(e: any) => {
                        if (e.relatedTarget === null) {
                            setIsOpen(false)
                        }
                    }}
                    onFocus={() => {
                        setIsOpen(true)
                    }}
                    disabled={disabled}
                    hasError={!!fieldMeta.error}
                />
                <DropdownComboboxButton
                    disabled={disabled}
                    open={open}
                    // onClick={() => setIsOpen(!open)}
                    hasError={!!fieldMeta.error}
                />
                <DropdownComboboxOptions open={open}>
                    <InfiniteDropdownOptions
                        isEmpty={!results?.length}
                        isLoading={queryResult.isLoading}
                        ref={observerRef}
                        hasNextPage={!!queryResult.hasNextPage}
                    >
                        {items.map((item) => {
                            const selected = field.value?.id === item.id

                            return (
                                <ComboboxDropdownItem
                                    key={item.id}
                                    item={item}
                                    selected={selected}
                                />
                            )
                        })}
                    </InfiniteDropdownOptions>
                </DropdownComboboxOptions>
            </>
        </DropdownCombobox>
    )
}

export default InfiniteDropdownWithSearchField

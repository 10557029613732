import React from 'react'
import { Outlet } from 'react-router-dom'

import Sidebar from './Sidebar'

const MainLayout: React.FC = () => {
    return (
        <div className="min-h-screen flex max-w-full print:min-h-0">
            <Sidebar />
            <div
                id="main-layout-right-side"
                className={
                    'flex flex-col flex-1 transition-all ml-64 main-layout-right-side print:m-0 print:max-w-full'
                }
            >
                <main className="flex flex-col flex-1 max-w-full">
                    <Outlet />
                </main>
            </div>
        </div>
    )
}

export default MainLayout

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Label, Dropdown, SearchField } from './ui'

import {
    DropdownItem,
    StatisticsDetailsListFilterAction,
    StatisticsDetailsListFilterState,
} from '../types'
import {
    useCountriesDropdown,
    useFoilSizesDropdown,
    useFoilTypesDropdown,
    useUserRoles,
} from '../hooks'
import { useCitiesQuery } from 'api/endpoints/places'
import { getDevices } from 'api/endpoints/devices'
import { getPattern } from 'api/endpoints/patterns'
import InfiniteDropdownWithSearch from './ui/InfiniteDropdownWithSearchFilter'
import { getPlotter, getUsers } from 'api'
import {
    supervisorMapFn,
    deviceMapFn,
    patternMapFn,
    plotterMapFn,
} from 'helpers'

const StatisticsDetailsListFilters: React.FC<{
    filters: StatisticsDetailsListFilterState
    filterAction: React.Dispatch<StatisticsDetailsListFilterAction>
}> = ({ filters, filterAction }) => {
    const { t } = useTranslation()
    const userRoles = useUserRoles()

    const ALL_ITEM: DropdownItem = { id: '', name: t('dictionary.all') }
    const countries = useCountriesDropdown()
    const cities = useCitiesQuery({ length: 99 })
    const foilTypes = useFoilTypesDropdown()
    const foilSizes = useFoilSizesDropdown()

    return (
        <div className="mt-3 px-4 py-3 mb-4 bg-gray-800 rounded-lg">
            <div className="grid grid-cols-5 gap-4">
                <div>
                    <Label>{t('filters.superior')}</Label>
                    <div className="shadow-sm rounded-md">
                        <InfiniteDropdownWithSearch
                            value={filters.superior}
                            queryFilters={{ roles: [userRoles.supervisor.id] }}
                            queryFn={getUsers}
                            name="superiors"
                            mapFn={supervisorMapFn}
                            setValue={(value: DropdownItem | undefined) => {
                                filterAction({
                                    type: 'setFilterSuperior',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.country')}</Label>
                    <div className="shadow-sm rounded-md">
                        <Dropdown
                            items={[ALL_ITEM].concat(countries)}
                            value={
                                countries.find(
                                    (item) => item.id === filters.country?.id
                                ) || ALL_ITEM
                            }
                            onChange={(value) => {
                                filterAction({
                                    type: 'setFilterCountry',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.region')}</Label>
                    <div className="shadow-sm rounded-md">
                        <SearchField
                            value={filters.region}
                            action={(value) => {
                                filterAction({
                                    type: 'setFilterRegion',
                                    payload: value || undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.city')}</Label>
                    <div className="shadow-sm rounded-md">
                        <Dropdown
                            items={[ALL_ITEM].concat(cities.data?.data || [])}
                            value={
                                cities.data?.data.find(
                                    (item) => item.id === filters.city?.id
                                ) || ALL_ITEM
                            }
                            onChange={(value) => {
                                filterAction({
                                    type: 'setFilterCity',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.plotter')}</Label>
                    <div className="shadow-sm rounded-md">
                        <InfiniteDropdownWithSearch
                            value={filters.plotter}
                            queryFn={getPlotter}
                            name="plotters"
                            mapFn={plotterMapFn}
                            queryFilters={{ assigned: true }}
                            setValue={(value: DropdownItem | undefined) => {
                                filterAction({
                                    type: 'setFilterPlotter',
                                    payload: value
                                        ? value.id &&
                                          value.id !== filters.plotter?.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.device')}</Label>
                    <div className="shadow-sm rounded-md">
                        <InfiniteDropdownWithSearch
                            value={filters.device}
                            queryFn={getDevices}
                            name="devices"
                            mapFn={deviceMapFn}
                            setValue={(value: DropdownItem | undefined) => {
                                filterAction({
                                    type: 'setFilterDevice',
                                    payload: value
                                        ? value.id &&
                                          value.id !== filters.device?.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.film')}</Label>
                    <div className="shadow-sm rounded-md">
                        <Dropdown
                            items={[ALL_ITEM].concat(foilTypes)}
                            value={
                                foilTypes.find(
                                    (item) => item.id === filters.type?.id
                                ) || ALL_ITEM
                            }
                            onChange={(value) => {
                                filterAction({
                                    type: 'setFilterType',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.size')}</Label>
                    <div className="shadow-sm rounded-md">
                        <Dropdown
                            items={[ALL_ITEM].concat(foilSizes)}
                            value={
                                foilSizes.find(
                                    (item) => item.id === filters.size?.id
                                ) || ALL_ITEM
                            }
                            onChange={(value) => {
                                filterAction({
                                    type: 'setFilterSize',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.pattern')}</Label>
                    <div className="shadow-sm rounded-md">
                        <InfiniteDropdownWithSearch
                            value={filters.pattern}
                            disabled={!filters.device?.id}
                            queryFilters={{ device_id: filters.device?.id }}
                            queryFn={getPattern}
                            name="patterns"
                            mapFn={patternMapFn}
                            setValue={(value: DropdownItem | undefined) => {
                                filterAction({
                                    type: 'setFilterPattern',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatisticsDetailsListFilters

import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Spinner } from './ui'
import TableFooter from './TableFooter'
import { Toggle, Button3dots } from './ui'
import Menu3dots from './Menu3dots'
import {
    TableHeader,
    TableContainer,
    Table,
    Tbody,
    Tempty,
    Tr,
} from './ui/List'

import {
    UserAddIcon,
    LockClosedIcon,
    PencilAltIcon,
} from '@heroicons/react/solid'
import { TrashIcon } from '@heroicons/react/outline'

import type { UseQueryResult } from 'react-query'
import type { PlotterListFilterState, PlotterListFilterAction } from '../types'
import type {
    ResponseList,
    PlotterListResponse,
    PlotterResponse,
} from '../api/types'
import { usePolicy } from 'hooks/usePolicy'
import SortButton from './ui/SortButton'

type PlotterListProps = {
    plottersQuery: UseQueryResult<ResponseList<PlotterListResponse>>
    filters: PlotterListFilterState
    filterCount: number
    filterAction?: React.Dispatch<PlotterListFilterAction>
    handleToggle?: (id: number, state: boolean) => void
    handleRemove?: (id: number) => void
    handleEdit?: (id: number, payload: PlotterResponse) => void
    handleAssign?: (id: number, payload: PlotterResponse) => void
    handleDetach?: (id: number) => void
    handleLock?: (id: number) => void
}

type TableProps = {
    data: PlotterListResponse
    handleToggle?: (id: number, state: boolean) => void
    handleRemove?: (id: number) => void
    handleEdit?: (id: number, payload: PlotterResponse) => void
    handleAssign?: (id: number, payload: PlotterResponse) => void
    handleDetach?: (id: number) => void
    handleLock?: (id: number) => void
}

const PlotterList: React.FC<PlotterListProps> = ({
    plottersQuery,
    filters,
    filterCount,
    filterAction,
    handleToggle,
    handleRemove,
    handleAssign,
    handleDetach,
    handleEdit,
    handleLock,
}) => {
    const { t } = useTranslation()

    return (
        <TableContainer>
            <Table>
                <TableHeader>
                    <th
                        scope="col"
                        className="w-[65px] px-6 py-4 text-left tracking-wider"
                    >
                        {filterAction ? (
                            <SortButton
                                disabled={plottersQuery.isLoading}
                                filters={filters}
                                action={filterAction}
                                name="id"
                            >
                                <div className="text-left tracking-wider uppercase">
                                    {t('list.plotter.id')}
                                </div>
                            </SortButton>
                        ) : (
                            t('list.plotter.id')
                        )}
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-4 text-left tracking-wider"
                    >
                        {filterAction ? (
                            <SortButton
                                disabled={plottersQuery.isLoading}
                                filters={filters}
                                action={filterAction}
                                name="name"
                            >
                                <div className="text-left tracking-wider uppercase">
                                    {t('list.plotter.name')}
                                </div>
                            </SortButton>
                        ) : (
                            t('list.plotter.name')
                        )}
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.plotter.owner')}
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.plotter.description')}
                    </th>
                    <th
                        scope="col"
                        className="w-auto min-w-[80px] px-6 py-4 text-left tracking-wider box-content"
                    >
                        {t('list.plotter.serial')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[80px] px-6 py-4 text-left tracking-wider box-content"
                    >
                        {t('list.plotter.status')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[65px] px-6 py-4 text-left tracking-wider"
                    ></th>
                </TableHeader>
                {plottersQuery.isSuccess && plottersQuery.isFetched && (
                    <>
                        {plottersQuery.data.meta.total > 0 && (
                            <TableBody
                                data={plottersQuery.data.data}
                                handleToggle={handleToggle}
                                handleRemove={handleRemove}
                                handleDetach={handleDetach}
                                handleAssign={handleAssign}
                                handleLock={handleLock}
                                handleEdit={handleEdit}
                            />
                        )}
                    </>
                )}
                {plottersQuery.data?.meta.total === 0 && (
                    <Tbody>
                        <Tr key={-1} index={0}>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4 relative h-12">
                                {filterCount === 0 && (
                                    <Tempty>
                                        {t('list.plotter.empty_list')}
                                    </Tempty>
                                )}
                                {filterCount > 0 && (
                                    <Tempty>
                                        {t('list.plotter.empty_search')}
                                    </Tempty>
                                )}
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                        </Tr>
                    </Tbody>
                )}
            </Table>
            {plottersQuery.isLoading && (
                <div className="divide-y divide-gray-700 text-sm leading-5">
                    {Array.from(Array(filters.length).keys()).map(
                        (_, index) => (
                            <div
                                key={index}
                                className={classNames('relative h-[68px]', {
                                    'bg-gray-800': index % 2,
                                    'bg-background': !(index % 2),
                                })}
                            >
                                {index === 4 && (
                                    <div className="absolute inset-0 h-full flex justify-center items-center">
                                        <Spinner className="p-0" />
                                    </div>
                                )}
                                <span>&nbsp;</span>
                            </div>
                        )
                    )}
                </div>
            )}
            {filterAction && (
                <TableFooter
                    meta={plottersQuery.data?.meta}
                    filterAction={filterAction}
                />
            )}
        </TableContainer>
    )
}

const TableBody: React.FC<TableProps> = ({
    data,
    handleToggle,
    handleAssign,
    handleRemove,
    handleDetach,
    handleEdit,
    handleLock,
}) => {
    const { t } = useTranslation()
    const policy = usePolicy()

    const isMutating = false
    return (
        <Tbody>
            {data.map((item, index) => (
                <Tr key={item.id} index={index}>
                    <td className="px-6 py-4">
                        <span>{item.id}</span>
                    </td>
                    <td className="px-6 py-4">{item.name}</td>
                    <td className="px-6 py-4">{item.user?.name || '-'}</td>
                    <td className="px-6 py-4">
                        <span>{item.description}</span>
                    </td>
                    <td className="px-6 py-4">{item.serial || '-'}</td>
                    <td className="px-6 py-4">
                        <Toggle
                            checked={item.active}
                            disabled={
                                !policy.isSupervisor() && !policy.isAdmin()
                            }
                            handleChange={
                                handleToggle
                                    ? (value) => handleToggle(item.id, value)
                                    : () => {}
                            }
                        />
                    </td>
                    <td className="px-6 py-4 text-right">
                        <Menu3dots>
                            {({ open }) => (
                                <>
                                    <Menu3dots.Button
                                        as={
                                            isMutating ? React.Fragment : 'span'
                                        }
                                    >
                                        <Button3dots
                                            open={open}
                                            loading={isMutating}
                                        />
                                    </Menu3dots.Button>
                                    <Menu3dots.Items>
                                        <>
                                            {handleEdit && (
                                                <div className="divide-y border-t-[1px] border-gray-700 py-1">
                                                    <Menu3dots.Item
                                                        onClick={() =>
                                                            handleEdit
                                                                ? handleEdit(
                                                                      item.id,
                                                                      item
                                                                  )
                                                                : {}
                                                        }
                                                    >
                                                        <PencilAltIcon className="w-5 h-5 mr-3" />
                                                        {t('list.plotter.edit')}
                                                    </Menu3dots.Item>
                                                </div>
                                            )}

                                            {handleAssign &&
                                                (policy.isSupervisor() ||
                                                    policy.isAdmin()) && (
                                                    <div className="divide-y py-1">
                                                        <Menu3dots.Item
                                                            onClick={() =>
                                                                handleAssign
                                                                    ? handleAssign(
                                                                          item.id,
                                                                          item
                                                                      )
                                                                    : {}
                                                            }
                                                        >
                                                            <UserAddIcon className="w-5 h-5 mr-3" />
                                                            {t(
                                                                'list.plotter.assign'
                                                            )}
                                                        </Menu3dots.Item>
                                                    </div>
                                                )}

                                            {handleRemove &&
                                                (policy.isSupervisor() ||
                                                    policy.isAdmin()) && (
                                                    <div className="divide-y border-t-[1px] border-gray-700 divide-gray-200 py-1">
                                                        <Menu3dots.Item
                                                            onClick={() =>
                                                                handleRemove
                                                                    ? handleRemove(
                                                                          item.id
                                                                      )
                                                                    : {}
                                                            }
                                                        >
                                                            <TrashIcon className="w-5 h-5 mr-3" />
                                                            {t(
                                                                'list.plotter.delete'
                                                            )}
                                                        </Menu3dots.Item>
                                                    </div>
                                                )}

                                            {handleLock &&
                                                (policy.isSupervisor() ||
                                                    policy.isAdmin()) && (
                                                    <div className="divide-y border-t-[1px] border-gray-700 divide-gray-200 py-1">
                                                        <Menu3dots.Item
                                                            disabled={
                                                                !item.active
                                                            }
                                                            onClick={() =>
                                                                handleLock
                                                                    ? handleLock(
                                                                          item.id
                                                                      )
                                                                    : {}
                                                            }
                                                        >
                                                            <LockClosedIcon className="w-5 h-5 mr-3" />
                                                            {t(
                                                                'list.plotter.lock'
                                                            )}
                                                        </Menu3dots.Item>
                                                    </div>
                                                )}

                                            {handleDetach &&
                                                (policy.isSupervisor() ||
                                                    policy.isAdmin()) && (
                                                    <div className="divide-y border-t-[1px] border-gray-700 divide-gray-200 py-1">
                                                        <Menu3dots.Item
                                                            onClick={() =>
                                                                handleDetach
                                                                    ? handleDetach(
                                                                          item.id
                                                                      )
                                                                    : {}
                                                            }
                                                        >
                                                            <TrashIcon className="w-5 h-5 mr-3" />
                                                            {t(
                                                                'list.plotter.detach'
                                                            )}
                                                        </Menu3dots.Item>
                                                    </div>
                                                )}
                                        </>
                                    </Menu3dots.Items>
                                </>
                            )}
                        </Menu3dots>
                    </td>
                </Tr>
            ))}
        </Tbody>
    )
}

export default PlotterList

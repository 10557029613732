import axios from 'axios'

import type { ResponseError } from './types'

const { REACT_APP_API_URL, REACT_APP_API_KEY } = process.env

export interface ApplyInterceptorsProps {
    getToken: () => string | null
    clearToken: () => void
}

if (typeof REACT_APP_API_URL === 'undefined') {
    throw new Error('API_URL not found in .env file.')
}

if (typeof REACT_APP_API_KEY === 'undefined') {
    throw new Error('API_KEY not found in .env file.')
}

const axiosInstance = axios.create()

axiosInstance.defaults.baseURL = REACT_APP_API_URL
axiosInstance.defaults.headers.common.Accept = 'application/json'
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json'

axiosInstance.interceptors.response.use(
    (response) => {
        if (response.headers['content-type'] !== 'application/json') {
            return Promise.resolve(response)
        }

        return response.data
    },
    async (error) => {
        const normalizedError: ResponseError = {
            message: 'Something went wrong',
            errors: {},
        }

        if (error.response) {
            const { data, status } = error.response

            if (data && data.message) {
                normalizedError.message = data.message
            }

            if (data && data.errors) {
                normalizedError.errors = Object.keys(data.errors).reduce(
                    (acc, fieldName) => ({
                        ...acc,
                        [fieldName]: data.errors[fieldName][0],
                    }),
                    {}
                )
            }

            normalizedError.status = status
        }

        return Promise.reject(normalizedError)
    }
)

export const applyAuthInterceptors = ({
    getToken,
    clearToken,
}: ApplyInterceptorsProps) => {
    axiosInstance.interceptors.request.use(
        async (config) => {
            config.headers['X-App-Token'] = REACT_APP_API_KEY

            const authToken = getToken()

            if (authToken) {
                config.headers.Authorization = `Bearer ${authToken}`
            }

            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )

    axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (!!error && typeof error == 'object') {
                const { status } = error

                if (status === 401) {
                    clearToken()
                }
            }

            return Promise.reject(error)
        }
    )
}

export default axiosInstance

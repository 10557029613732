import React, { useCallback, useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import {
    DEFAULT_LIST_PER_PAGE,
    QUERY_CACHE_PATTERNS_KEY,
} from '../constants/index'
import {
    downloadPltFile,
    useCreatePattern,
    useDeviceByIdQuery,
    usePatternQuery,
    useUpdatePattern,
} from '../api'
import { listReducer } from '../helpers'
import { Button, Loader } from '../components/ui'
import PageLayout from '../components/PageLayout'
import PatternList from '../components/PatternList'

import type {
    PatternListFilterState,
    PatternListFilterAction,
    ListFilterAction,
    PatternForm,
    FormSubmitFn,
} from '../types'
import ConfirmModal from '../components/ConfirmModal'
import { useDeletePatternForm } from '../hooks/forms/useDeletePatternForm'
import { useQueryClient } from 'react-query'
import PatternModal from '../components/PatternModal'
import { useFlashMessage, useModal } from '../hooks'
import { PatternResponse } from '../api/types'
import { usePolicy } from '../hooks/usePolicy'
import { useParams } from 'react-router-dom'

const filterReducer = (
    state: PatternListFilterState,
    action: PatternListFilterAction
) => {
    if (action.type === 'setFilterQuery') {
        return {
            ...state,
            search_global: action.payload,
            page: 1,
        }
    }

    if (action.type === 'resetFilters') {
        return {
            ...state,
            search_global: '',
            page: 1,
        }
    }

    return listReducer(
        state,
        action as ListFilterAction
    ) as PatternListFilterState
}

export default function DevicePatternListView() {
    const { device_id } = useParams()

    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const flashMessage = useFlashMessage()
    const createPatternModal = useModal()
    const updatePatternModal = useModal<{
        id: number
        payload: PatternResponse
    }>()

    const deletePatternForm = useDeletePatternForm(() => {
        queryClient.invalidateQueries([QUERY_CACHE_PATTERNS_KEY, 'index'])
    })

    const createPattern = useCreatePattern()
    const updatePattern = useUpdatePattern()
    const [filters, dispatchFilterAction] = useReducer(filterReducer, {
        search_global: '',
        sort_by: 'id',
        sort_direction: 'desc',
        length: DEFAULT_LIST_PER_PAGE,
        page: 1,
    })
    const filterCount = 0

    const apiFilters = useMemo(() => {
        return {
            ...filters,
            search_global: filters.search_global || undefined,
            device_id: device_id,
        }
    }, [filters, device_id])

    const downloadPltFileHandler = useCallback(
        async (pattern: PatternResponse) => {
            const response = await downloadPltFile(pattern.id)

            const href = URL.createObjectURL(
                new Blob([response.data], {
                    type: (response as any).headers['content-type'],
                })
            )

            const link = document.createElement('a')
            link.href = href
            link.setAttribute(
                'download',
                `${pattern.id} ${pattern.brand || ''} ${pattern.name}.plt`
            )
            document.body.appendChild(link)
            link.click()

            document.body.removeChild(link)
            URL.revokeObjectURL(href)
        },
        []
    )

    const handleSubmit: FormSubmitFn<PatternForm> = (values, formikHelpers) => {
        createPattern.mutate(
            {
                data: {
                    name: values.name,
                    device: parseInt(device_id!),
                    size: String(values.size?.id),
                    plt: values.plt,
                    svg: values.svg,
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.pattern.saved'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_PATTERNS_KEY,
                        'index',
                    ])
                    createPatternModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const handleSubmitEdit: FormSubmitFn<PatternForm> = (
        values,
        formikHelpers
    ) => {
        updatePattern.mutate(
            {
                id: updatePatternModal.getState()!.id,
                data: {
                    name: values.name,
                    size: String(values.size?.id),
                    plt: values.plt || undefined,
                    svg: values.svg || undefined,
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.pattern.updated'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_PATTERNS_KEY,
                        'index',
                    ])
                    updatePatternModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const patternsQuery = usePatternQuery(apiFilters)
    const device = useDeviceByIdQuery(device_id!)
    const policy = usePolicy()

    if (!policy.isAdmin()) {
        return <div>Forbidden</div>
    }

    if (device.isLoading) {
        return <Loader />
    }

    if (device.isError) {
        return <div className="text-red-600">{device.error.message}</div>
    }

    if (!device.isSuccess) {
        return null
    }

    return (
        <PageLayout
            title={t('list.pattern.title')}
            breadCrumbs={[
                {
                    name: t('bread.device'),
                    url: '/device',
                },
                {
                    name: t('bread.pattern'),
                    url: '/patterns',
                },
                {
                    name: device.data.data.name || '',
                },
            ]}
            searchBarAction={(value) =>
                dispatchFilterAction({
                    type: 'setFilterQuery',
                    payload: value,
                })
            }
            actions={
                <>
                    <span className="ml-2">
                        <Button onClick={createPatternModal.openModal}>
                            {t('action.pattern.create')}
                        </Button>
                    </span>
                </>
            }
        >
            <>
                <PatternList
                    patternsQuery={patternsQuery}
                    filters={filters}
                    filterCount={filterCount + filters.search_global.length}
                    handleEdit={(id, payload) => {
                        updatePatternModal.setState({ id, payload })
                        updatePatternModal.openModal()
                    }}
                    extendedData={false}
                    handlePltDownload={downloadPltFileHandler}
                    handleRemove={
                        policy.isAdmin()
                            ? (id) => {
                                  deletePatternForm.modal.setState(id)
                                  deletePatternForm.modal.openModal()
                              }
                            : undefined
                    }
                    filterAction={dispatchFilterAction}
                />
                <PatternModal
                    modal={createPatternModal}
                    onSubmit={handleSubmit}
                />
                <PatternModal
                    modal={updatePatternModal}
                    patternData={updatePatternModal.getState()?.payload}
                    onSubmit={handleSubmitEdit}
                />
                <ConfirmModal
                    modal={deletePatternForm.modal}
                    title={t('delete.pattern.title')}
                    description={t('delete.pattern.description')}
                    actionLabel={t('action.delete')}
                    onSubmit={deletePatternForm.form.submitForm}
                    isSubmitting={deletePatternForm.form.isSubmitting}
                />
            </>
        </PageLayout>
    )
}

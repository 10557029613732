import React, { Fragment } from 'react'
import {
    Listbox,
    ListboxButton,
    ListboxOptions,
    Transition,
} from '@headlessui/react'

import DropdownButton from './DropdownBase/DropdownButton'

import type { DropdownItem } from '../../../types'
import { ListboxDropdownItem } from './DropdownBase/DropdownItem'
import classNames from 'classnames'

export interface DropdownProps {
    value?: DropdownItem
    items: DropdownItem[]
    disabled?: boolean
    hasError?: boolean
    onChange?: (value?: DropdownItem) => void
    anchor?: 'bottom' | 'top'
}

export default function Dropdown({
    value,
    items,
    disabled,
    hasError,
    onChange,
    anchor,
}: DropdownProps) {
    return (
        <Listbox
            value={value}
            onChange={(value?) => !!onChange && onChange(value)}
        >
            {({ open }) => (
                <div className="relative">
                    <DropdownButton
                        as={ListboxButton}
                        value={value}
                        variant="md"
                        isOpen={open}
                        hasError={hasError}
                        disabled={disabled}
                    />

                    <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <ListboxOptions
                            anchor={anchor}
                            className={classNames(
                                'w-[var(--button-width)] absolute z-10 bg-gray-600 text-gray-50 border border-gray-800 shadow-sm max-h-60 [--anchor-max-height:13rem] rounded-md py-1 text-base overflow-auto focus:outline-none sm:text-sm',
                                {
                                    'mt-1': !anchor || anchor === 'bottom',
                                    'mb-1': anchor === 'top',
                                }
                            )}
                        >
                            {items.map((item) => {
                                const selected = value?.id === item.id

                                return (
                                    <ListboxDropdownItem
                                        key={item.id}
                                        item={item}
                                        selected={selected}
                                    />
                                )
                            })}
                        </ListboxOptions>
                    </Transition>
                </div>
            )}
        </Listbox>
    )
}

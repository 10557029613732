import React from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'

import type { ButtonProps } from './Button'
import { ListFilterAction, ListFilterState } from 'types'
import classNames from 'classnames'

export type SortButtonProps = {
    disabled: ButtonProps['loading']
    name: string
    filters: ListFilterState
    action: React.Dispatch<ListFilterAction>
    children: React.ReactNode
}

export default function SortButton({
    filters,
    action,
    name,
    disabled,
    children,
}: SortButtonProps) {
    return (
        <button
            type="button"
            className="align-middle outline-none"
            onClick={() => {
                if (filters.sort_by === name) {
                    action({
                        type: 'setSortingDirection',
                        payload:
                            filters.sort_direction === 'asc' ? 'desc' : 'asc',
                    })
                } else {
                    action({
                        type: 'setSorting',
                        payload: name,
                    })
                }
            }}
            disabled={disabled}
        >
            <div className="h-4 align-middle inline-block">{children}</div>
            <div
                className={classNames('ml-2 align-middle inline-block', {
                    'text-gray-500': filters.sort_by !== name,
                })}
            >
                {filters.sort_direction === 'asc' ? (
                    <ChevronUpIcon className="w-auto h-4" />
                ) : (
                    <ChevronDownIcon className="w-auto h-4" />
                )}
            </div>
        </button>
    )
}

import React, { useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import {
    DEFAULT_LIST_PER_PAGE,
    QUERY_CACHE_PLOTTERS_KEY,
} from '../constants/index'
import { useCreatePlotter, usePlotterQuery, useUpdatePlotter } from '../api'
import { listReducer } from '../helpers'
import { Button } from '../components/ui'
import PageLayout from '../components/PageLayout'
import PlotterList from '../components/PlotterList'

import type {
    PlotterListFilterState,
    PlotterListFilterAction,
    ListFilterAction,
    PlotterForm,
    FormSubmitFn,
    AssignUserForm,
} from '../types'
import ConfirmModal from '../components/ConfirmModal'
import { useActivePlotterForm } from '../hooks/forms/usePlotterActiveForm'
import { useDeletePlotterForm } from '../hooks/forms/useDeletePlotterForm'
import { useQueryClient } from 'react-query'
import PlotterModal from '../components/PlotterModal'
import { useFlashMessage, useModal } from '../hooks'
import { PlotterResponse } from '../api/types'
import SelectUserModal from '../components/SelectUserModal'
import { usePolicy } from '../hooks/usePolicy'

const filterReducer = (
    state: PlotterListFilterState,
    action: PlotterListFilterAction
) => {
    if (action.type === 'setFilterQuery') {
        return {
            ...state,
            search_global: action.payload,
            page: 1,
        }
    }

    if (action.type === 'resetFilters') {
        return {
            ...state,
            search_global: '',
            page: 1,
        }
    }

    return listReducer(
        state,
        action as ListFilterAction
    ) as PlotterListFilterState
}

export default function PlotterListView() {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const flashMessage = useFlashMessage()
    const createPlotterModal = useModal()
    const updatePlotterModal = useModal<{
        id: number
        payload: PlotterResponse
    }>()
    const assignPlotterModal = useModal<{
        id: number
        payload: PlotterResponse
    }>()

    const activePlotterForm = useActivePlotterForm(() => {
        queryClient.invalidateQueries([QUERY_CACHE_PLOTTERS_KEY, 'index'])
    })
    const deletePlotterForm = useDeletePlotterForm(() => {
        queryClient.invalidateQueries([QUERY_CACHE_PLOTTERS_KEY, 'index'])
    })

    const createPlotter = useCreatePlotter()
    const updatePlotter = useUpdatePlotter()
    const [filters, dispatchFilterAction] = useReducer(filterReducer, {
        search_global: '',
        sort_by: 'id',
        sort_direction: 'desc',
        length: DEFAULT_LIST_PER_PAGE,
        page: 1,
    })

    const apiFilters = useMemo(() => {
        return {
            ...filters,
            status: undefined,
            search_global: filters.search_global || undefined,
        }
    }, [filters])

    const handleSubmit: FormSubmitFn<PlotterForm> = (values, formikHelpers) => {
        createPlotter.mutate(
            {
                data: {
                    name: values.name,
                    description: values.description,
                    serial: values.serial,
                    active: values.active,
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.plotter.saved'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_PLOTTERS_KEY,
                        'index',
                    ])
                    createPlotterModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const handleSubmitEdit: FormSubmitFn<PlotterForm> = (
        values,
        formikHelpers
    ) => {
        updatePlotter.mutate(
            {
                id: updatePlotterModal.getState()!.id,
                data: {
                    name: values.name,
                    description: values.description,
                    serial: values.serial,
                    active: values.active,
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.plotter.updated'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_PLOTTERS_KEY,
                        'index',
                    ])
                    updatePlotterModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const handleSubmitAssign: FormSubmitFn<AssignUserForm> = (
        values,
        formikHelpers
    ) => {
        updatePlotter.mutate(
            {
                id: assignPlotterModal.getState()!.id,
                data: {
                    user: values.assignedUser?.id || null,
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.plotter.updated'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_PLOTTERS_KEY,
                        'index',
                    ])
                    assignPlotterModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const plottersQuery = usePlotterQuery(apiFilters)

    const policy = usePolicy()

    return (
        <PageLayout
            title={t('plotter.list.title')}
            searchBarAction={(value) =>
                dispatchFilterAction({
                    type: 'setFilterQuery',
                    payload: value,
                })
            }
            actions={
                <>
                    <span className="ml-2">
                        <Button onClick={createPlotterModal.openModal}>
                            {t('action.plotter.create')}
                        </Button>
                    </span>
                </>
            }
        >
            <>
                <PlotterList
                    plottersQuery={plottersQuery}
                    filters={filters}
                    filterCount={filters.search_global.length}
                    handleToggle={
                        !activePlotterForm.form.isSubmitting
                            ? (id, state) => {
                                  activePlotterForm.modal.setState(id)
                                  if (state === false) {
                                      activePlotterForm.form.setValues({
                                          active: false,
                                      })
                                      activePlotterForm.modal.openModal()
                                  } else {
                                      activePlotterForm.form.setValues({
                                          active: true,
                                      })
                                      activePlotterForm.form.submitForm()
                                  }
                              }
                            : undefined
                    }
                    handleEdit={(id, payload) => {
                        updatePlotterModal.setState({ id, payload })
                        updatePlotterModal.openModal()
                    }}
                    handleRemove={
                        policy.isAdmin()
                            ? (id) => {
                                  deletePlotterForm.modal.setState(id)
                                  deletePlotterForm.modal.openModal()
                              }
                            : undefined
                    }
                    filterAction={dispatchFilterAction}
                    handleAssign={(id, payload) => {
                        assignPlotterModal.setState({ id, payload })
                        assignPlotterModal.openModal()
                    }}
                    handleLock={
                        policy.isSupervisor()
                            ? (id) => {
                                  activePlotterForm.modal.setState(id)
                                  activePlotterForm.form.setValues({
                                      active: false,
                                  })
                                  activePlotterForm.modal.openModal()
                              }
                            : undefined
                    }
                />
                <PlotterModal
                    modal={createPlotterModal}
                    onSubmit={handleSubmit}
                />
                <PlotterModal
                    modal={updatePlotterModal}
                    plotterData={updatePlotterModal.getState()?.payload}
                    onSubmit={handleSubmitEdit}
                />
                <ConfirmModal
                    modal={activePlotterForm.modal}
                    title={t('deactivate.plotter.title')}
                    description={t('deactivate.plotter.description')}
                    actionLabel={t('deactivate.plotter.action')}
                    onSubmit={activePlotterForm.form.submitForm}
                    isSubmitting={activePlotterForm.form.isSubmitting}
                />
                <ConfirmModal
                    modal={deletePlotterForm.modal}
                    title={t('delete.plotter.title')}
                    description={t('delete.plotter.description')}
                    actionLabel={t('delete.plotter.action')}
                    onSubmit={deletePlotterForm.form.submitForm}
                    isSubmitting={deletePlotterForm.form.isSubmitting}
                />
                <SelectUserModal
                    modal={assignPlotterModal}
                    onSubmit={handleSubmitAssign}
                    initialUser={assignPlotterModal.getState()?.payload.user}
                    title={t('assign.user.title')}
                    description={t('assign.user.description')}
                />
            </>
        </PageLayout>
    )
}

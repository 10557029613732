import { plugins, scales } from 'chart.js'

export const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
        padding: {
            left: 0,
        },
    },
    plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
                boxWidth: 6,
                boxHeight: 6,
                borderRadius: 3,
                useBorderRadius: true,
            },
            color: '#9CA3AF',
            font: {
                size: 16,
            },
        },
        title: {
            display: false,
        },
        tooltip: {
            backgroundColor: '#6B7280',
            displayColors: false,
            titleColor: '#E5E7EB',
            titleFont: {
                size: 12,
            },
            bodyColor: '#FFF',
            bodyFont: {
                size: 14,
            },
            callbacks: {
                afterTitle: (item: any) => item[0]?.dataset?.label,
                label: (item: any) =>
                    item?.dataset?.data.find(
                        (data: number | null) => data != null
                    ),
            },
        },
    },
    scales: {
        x: {
            border: {
                display: true,
                width: 3,
                color: '#9CA3AF',
                z: 1,
            },
            ticks: {
                display: true,
                major: {
                    enabled: false,
                },
            },
            grid: {
                display: true,
                tickColor: '#9CA3AF',
                tickLength: 15,
                tickWidth: 3,
                offset: false,
            },
            offset: true,
        },
        y: {
            border: {
                display: false,
            },
            ticks: {
                padding: 0,
                display: true,
                color: '#F9FAFB',
                major: {
                    enabled: false,
                },
                font: {
                    size: 14,
                },
            },
            grid: {
                display: true,
                color: '#374151',
                tickLength: 15,
                tickWidth: 0,
            },
        },
    },
}

export const seriesChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    type: 'line',
    plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
                boxWidth: 6,
                boxHeight: 6,
                borderRadius: 3,
                useBorderRadius: true,
            },
            color: '#9CA3AF',
            font: {
                size: 16,
            },
        },
        title: {
            display: false,
        },
        tooltip: {
            backgroundColor: '#6B7280',
        },
    },
    scales: {
        x: {
            border: {
                display: true,
                width: 3,
                color: '#9CA3AF',
                z: 1,
            },
            ticks: {
                display: true,
                color: '#9CA3AF',
                font: {
                    size: 12,
                },
                major: {
                    enabled: false,
                },
            },
            grid: {
                display: true,
                tickColor: '#9CA3AF',
                tickLength: 15,
                tickWidth: 3,
            },
        },
        y: {
            border: {
                display: false,
            },
            ticks: {
                display: true,
                color: '#F9FAFB',
                font: {
                    size: 14,
                },
                major: {
                    enabled: false,
                },
            },
            grid: {
                display: true,
                color: '#374151',
                tickLength: 15,
                tickWidth: 0,
            },
        },
    },
}

const printColorGray = '#374151'

export const seriesChartPrintOptions = {
    ...seriesChartOptions,
    animation: false,
    animations: {
        colors: false,
        x: false,
        transitions: {
            active: {
                animation: {
                    duration: 0,
                },
            },
        },
    },
    tooltip: {
        enabled: false,
    },
    plugins: {
        ...seriesChartOptions.plugins,
        tooltip: {
            enabled: false,
        },
    },
    scales: {
        x: {
            ...seriesChartOptions.scales.x,
            border: {
                ...seriesChartOptions.scales.x.border,
                color: printColorGray,
            },
            ticks: {
                ...seriesChartOptions.scales.x.ticks,
                color: printColorGray,
            },
            grid: {
                ...seriesChartOptions.scales.x.grid,
                lineWidth: 0,
                tickColor: printColorGray,
            },
        },
        y: {
            ...seriesChartOptions.scales.y,
            ticks: {
                ...seriesChartOptions.scales.y.ticks,
                color: printColorGray,
            },
            grid: {
                ...seriesChartOptions.scales.y.grid,
                color: printColorGray,
            },
        },
    },
}

export const barChartPrintOptions = {
    ...barChartOptions,
    animation: false,
    animations: {
        colors: false,
        x: false,
        transitions: {
            active: {
                animation: {
                    duration: 0,
                },
            },
        },
    },
    tooltip: {
        enabled: false,
    },
    scales: {
        x: {
            ...barChartOptions.scales.x,
            border: {
                ...barChartOptions.scales.x.border,
                color: printColorGray,
            },
            grid: {
                ...barChartOptions.scales.x.grid,
                lineWidth: 0,
                tickColor: printColorGray,
            },
        },
        y: {
            ...barChartOptions.scales.y,
            ticks: {
                ...barChartOptions.scales.y.ticks,
                color: printColorGray,
            },
            grid: {
                ...barChartOptions.scales.y.grid,
                color: printColorGray,
            },
        },
    },
}

export const barChartFromSerialOptions = {
    ...barChartOptions,
    animation: false,
    animations: {
        colors: false,
        x: false,
        transitions: {
            active: {
                animation: {
                    duration: 0,
                },
            },
        },
    },
    plugins: {
        ...barChartOptions.plugins,
        tooltip: {
            ...barChartOptions.plugins.tooltip,
            callbacks: {
                afterTitle: (item: any) => item[0]?.dataset?.label,
                label: (item: any) => item?.formattedValue,
            },
        },
    },
}

export const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    type: 'pie',
    plugins: {
        title: {
            display: false,
        },
        legend: {
            position: 'bottom' as const,
            labels: {
                boxWidth: 12,
                boxHeight: 12,
                borderRadius: 6,
                useBorderRadius: true,
            },
            color: '#9CA3AF',
            font: {
                size: 16,
            },
        },
        tooltip: {
            backgroundColor: '#6B7280',
            displayColors: false,
            titleColor: '#E5E7EB',
            titleFont: {
                size: 12,
            },
            bodyColor: '#FFF',
            bodyFont: {
                size: 14,
            },
            callbacks: {
                label: (item: any) => item.formattedValue,
            },
        },
    },
}

export const piePrintOptions = {
    ...pieOptions,
    animation: false,
    animations: {
        colors: false,
        x: false,
        transitions: {
            active: {
                animation: {
                    duration: 0,
                },
            },
        },
    },
    borderColor: '#FFFFFF',
    plugin: {
        ...pieOptions.plugins,
        legend: {
            ...pieOptions.plugins.legend,
            color: '#FFFFFF',
        },
    },
}

export const transposedBarChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
                boxWidth: 6,
                boxHeight: 6,
                borderRadius: 3,
                useBorderRadius: true,
            },
            color: '#9CA3AF',
            font: {
                size: 16,
            },
        },
        title: {
            display: false,
        },
        tooltip: {
            backgroundColor: '#6B7280',
            displayColors: false,
            titleColor: '#E5E7EB',
            titleFont: {
                size: 12,
            },
            bodyColor: '#FFF',
            bodyFont: {
                size: 14,
            },
            callbacks: {
                afterTitle: (item: any) => item[0]?.dataset?.label,
                label: (item: any) =>
                    item?.dataset?.data.find(
                        (data: number | null) => data != null
                    ),
            },
        },
    },
    scales: {
        y: {
            border: {
                display: false,
            },
            ticks: {
                display: true,
                major: {
                    enabled: false,
                },
            },
            grid: {
                display: true,
                tickColor: '#9CA3AF',
                tickLength: 15,
                tickWidth: 0,
            },
            offset: true,
        },
        x: {
            border: {
                display: true,
                width: 3,
                color: '#9CA3AF',
                z: 1,
            },
            ticks: {
                display: true,
                color: '#F9FAFB',
                major: {
                    enabled: false,
                },
                font: {
                    size: 14,
                },
            },
            grid: {
                display: true,
                color: '#374151',
                tickLength: 0,
                tickWidth: 0,
            },
        },
    },
    indexAxis: 'y',
}

export const transposedBarChartPrintOptions = {
    ...transposedBarChartOptions,
    animation: false,
    animations: {
        colors: false,
        x: false,
        transitions: {
            active: {
                animation: {
                    duration: 0,
                },
            },
        },
    },
    plugins: {
        ...transposedBarChartOptions.plugins,
        tooltip: {
            enabled: false,
        },
    },
    scales: {
        x: {
            ...transposedBarChartOptions.scales.x,
            border: {
                color: printColorGray,
            },
        },
        y: {
            ...transposedBarChartOptions.scales.y,
            grid: {
                display: false,
            },
        },
    },
}

import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Spinner } from './ui'
import TableFooter from './TableFooter'
import { Button3dots } from './ui'
import Menu3dots from './Menu3dots'
import {
    TableHeader,
    TableContainer,
    Table,
    Tbody,
    Tempty,
    Tr,
} from './ui/List'

import { PencilAltIcon } from '@heroicons/react/solid'
import { TrashIcon, PhotographIcon } from '@heroicons/react/outline'

import type { UseQueryResult } from 'react-query'
import type { BrandListFilterState, BrandListFilterAction } from '../types'
import type {
    ResponseList,
    BrandListResponse,
    BrandResponse,
} from '../api/types'
import SortButton from './ui/SortButton'

type BrandListProps = {
    brandsQuery: UseQueryResult<ResponseList<BrandListResponse>>
    filters: BrandListFilterState
    filterCount: number
    filterAction: React.Dispatch<BrandListFilterAction>
    handleRemove?: (id: number) => void
    handleEdit?: (id: number, payload: BrandResponse) => void
}

type TableProps = {
    data: BrandListResponse
    handleRemove?: (id: number) => void
    handleEdit?: (id: number, payload: BrandResponse) => void
}

const BrandList: React.FC<BrandListProps> = ({
    brandsQuery,
    filters,
    filterCount,
    filterAction,
    handleRemove,
    handleEdit,
}) => {
    const { t } = useTranslation()

    return (
        <TableContainer>
            <Table>
                <TableHeader>
                    <th scope="col" className="w-[95px] px-6 py-4">
                        <SortButton
                            disabled={brandsQuery.isLoading}
                            filters={filters}
                            action={filterAction}
                            name="id"
                        >
                            <div className="text-left tracking-wider uppercase">
                                {t('list.brand.id')}
                            </div>
                        </SortButton>
                    </th>
                    <th
                        scope="col"
                        className="w-[95px] px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.brand.logo')}
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-4 text-left tracking-wider"
                    >
                        <SortButton
                            disabled={brandsQuery.isLoading}
                            filters={filters}
                            action={filterAction}
                            name="name"
                        >
                            <div className="text-left tracking-wider uppercase">
                                {t('list.brand.name')}
                            </div>
                        </SortButton>
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[160px] px-6 py-4 text-left tracking-wider"
                    >
                        <SortButton
                            disabled={brandsQuery.isLoading}
                            filters={filters}
                            action={filterAction}
                            name="order"
                        >
                            <div className="text-left tracking-wider uppercase">
                                {t('list.brand.order')}
                            </div>
                        </SortButton>
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[100px] px-6 py-4 text-left tracking-wider box-content"
                    >
                        {t('list.brand.devices_count')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[65px] px-6 py-4 text-left tracking-wider"
                    ></th>
                </TableHeader>
                {brandsQuery.isSuccess && brandsQuery.isFetched && (
                    <>
                        {brandsQuery.data.meta.total > 0 && (
                            <TableBody
                                data={brandsQuery.data.data}
                                handleRemove={handleRemove}
                                handleEdit={handleEdit}
                            />
                        )}
                    </>
                )}
                {brandsQuery.data?.meta.total === 0 && (
                    <Tbody>
                        <Tr key={-1} index={0}>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4 relative h-12">
                                {filterCount === 0 && (
                                    <Tempty>
                                        {t('list.brand.empty_list')}
                                    </Tempty>
                                )}
                                {filterCount > 0 && (
                                    <Tempty>
                                        {t('list.brand.empty_search')}
                                    </Tempty>
                                )}
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                        </Tr>
                    </Tbody>
                )}
            </Table>
            {brandsQuery.isLoading && (
                <div className="divide-y divide-gray-700 text-sm leading-5">
                    {Array.from(Array(filters.length).keys()).map(
                        (_, index) => (
                            <div
                                key={index}
                                className={classNames('relative h-[68px]', {
                                    'bg-gray-800': index % 2,
                                    'bg-background': !(index % 2),
                                })}
                            >
                                {index === 4 && (
                                    <div className="absolute inset-0 h-full flex justify-center items-center">
                                        <Spinner className="p-0" />
                                    </div>
                                )}
                                <span>&nbsp;</span>
                            </div>
                        )
                    )}
                </div>
            )}
            {filterAction && (
                <TableFooter
                    meta={brandsQuery.data?.meta}
                    filterAction={filterAction}
                />
            )}
        </TableContainer>
    )
}

const TableBody: React.FC<TableProps> = ({
    data,
    handleRemove,
    handleEdit,
}) => {
    const { t } = useTranslation()

    const isMutating = false
    return (
        <Tbody>
            {data.map((item, index) => (
                <Tr key={item.id} index={index}>
                    <td className="px-6 py-4">
                        <span>{item.id}</span>
                    </td>
                    <td className="px-6 py-4">
                        <span className="block w-10 h-10 relative">
                            {item.logo_url ? (
                                <img
                                    className="max-h-full m-auto absolute top-0 bottom-0 left-0 right-0"
                                    alt={item.name}
                                    style={{
                                        filter: 'brightness(0) invert(1)',
                                    }}
                                    src={item.logo_url}
                                />
                            ) : (
                                <span />
                            )}
                        </span>
                    </td>
                    <td className="px-6 py-4">{item.name}</td>
                    <td className="px-6 py-4 text-center">
                        {item.order || '-'}
                    </td>
                    <td className="px-6 py-4 text-center">
                        {item.devices_count || '-'}
                    </td>
                    <td className="px-6 py-4 text-right">
                        <Menu3dots>
                            {({ open }) => (
                                <>
                                    <Menu3dots.Button
                                        as={
                                            isMutating ? React.Fragment : 'span'
                                        }
                                    >
                                        <Button3dots
                                            open={open}
                                            loading={isMutating}
                                        />
                                    </Menu3dots.Button>
                                    <Menu3dots.Items>
                                        <>
                                            {handleEdit && (
                                                <div className="divide-y border-t-[1px] border-gray-700 py-1">
                                                    <Menu3dots.Item
                                                        onClick={() =>
                                                            handleEdit
                                                                ? handleEdit(
                                                                      item.id,
                                                                      item
                                                                  )
                                                                : {}
                                                        }
                                                    >
                                                        <PencilAltIcon className="w-5 h-5 mr-3" />
                                                        {t('list.brand.edit')}
                                                    </Menu3dots.Item>
                                                </div>
                                            )}

                                            <div className="divide-y py-1">
                                                <Menu3dots.Item
                                                    as="link"
                                                    to={`/brand/${item.id}/devices`}
                                                >
                                                    <PhotographIcon className="w-5 h-5 mr-3" />
                                                    {t('list.brand.devices')}
                                                </Menu3dots.Item>
                                            </div>

                                            {handleRemove && (
                                                <div className="divide-y border-t-[1px] border-gray-700 divide-gray-200 py-1">
                                                    <Menu3dots.Item
                                                        onClick={() =>
                                                            handleRemove
                                                                ? handleRemove(
                                                                      item.id
                                                                  )
                                                                : {}
                                                        }
                                                    >
                                                        <TrashIcon className="w-5 h-5 mr-3" />
                                                        {t('list.brand.delete')}
                                                    </Menu3dots.Item>
                                                </div>
                                            )}
                                        </>
                                    </Menu3dots.Items>
                                </>
                            )}
                        </Menu3dots>
                    </td>
                </Tr>
            ))}
        </Tbody>
    )
}

export default BrandList
